<div #mainContainer >
<div class="overlayContDiv d-none-custom-form" id="overlayContDiv" >
    
    <div  class="overlayMainDiv d-none-custom-form" id="overlayMainDiv" >
        <div id="clear-msg" class="clearHeading">Are you sure to clear all fields?</div>
        <div class="d-flex-custom-form justify-content-center mrgt-20" >
            <div class="btn btn-secondary ml-5 clearFormOverlay text-white cursor" id="clear-func" (click)="clearForm()">Clear</div>

            <div class="btn btn-danger mrgl-20" id="clear-cancel" (click)="showHideOverlayForm(0,'clear')">Cancel</div>
        </div>
    </div>


    <div  class="overlayMainDiv d-none-custom-form" id="overlayMainDivPersonalDetails" >
        <div id="clear-msg" class="clearHeading">Are you sure to submit the form?</div>
        <div class="d-flex-custom-form justify-content-center mrgt-20" >
            <button class="btn btn-danger clearBtnForm" id="submitCancelBtn" (click)="showHideOverlayPersonalDetailsForm(0)">Cancel</button>
            <button class="btn btn-secondary ml-5 submitBtnForm clearFormOverlay text-white cursor  mrgl-20" id="finalsubmitBtn" (click)="finalsubmitFormFunc()">Submit</button>
        </div>
    </div>


    <div  class="overlayMainDiv" id="overlayMainDivSuccessful" style="display: none;">
        <div id="successful-msg" class="successful-msg">Successfully Submitted</div>
        <div #redirectingPayment *ngIf="formResponse.paymentOptionUuid!=null" class="d-none-custom-form mrgt-20">Redirecting to Payment Area</div>
    </div>

</div>

<div class="w-100-custom-form bg-ddd"  >
    <div class="h-20"></div>

    <div *ngIf="clientDetails!=null" class="d-flex justify-content-center w-100-custom-form text-center" >
        <img src="{{(clientDetails!=null) ? clientDetails.logoUrl : ''}}" class="clientLogo"/>
    </div>

    <div class="form-container bg-white mrgt-20" >

        <div *ngIf="jsonStruct.length>0">
        <div *ngFor=" let data of jsonStruct; index as i"  class="p-2" id="container-{{i}}">
            
            <div *ngIf="data.length>0" class="d-flex-custom-form-768">
                <div *ngFor="let subdata of data;index as j"  class=" w-100-custom-form">
                <app-heading [formsPositionArray]="json"  [indexofelement]="subdata.element" *ngIf="subdata?.elementType == this.constants.HEADING " ></app-heading>

                <app-selectbox [formsPositionArray]="json"  [indexofelement]="subdata.element" *ngIf="subdata?.elementType == this.constants.SELECTBOX " (changeDynamicValue)="changeDynamicValue($event)"></app-selectbox>
                <app-textbox  [formsPositionArray]="json"  [indexofelement]="subdata.element"  *ngIf="subdata?.elementType == this.constants.TEXTBOX " ></app-textbox>
                <app-textarea [formsPositionArray]="json"  [indexofelement]="subdata.element" *ngIf="subdata?.elementType == this.constants.TEXTAREA " ></app-textarea>
                <app-radio  [formsPositionArray]="json"  [indexofelement]="subdata.element" *ngIf="subdata?.elementType == this.constants.RADIO " ></app-radio>
                <app-date-selector  [formsPositionArray]="json"  [indexofelement]="subdata.element"  *ngIf="subdata?.elementType == this.constants.DATESELECTOR " ></app-date-selector>
                <app-paragraph [formsPositionArray]="json"  [indexofelement]="subdata.element" *ngIf="subdata?.elementType == this.constants.PARAGRAPH " ></app-paragraph>
                <app-separator  [formsPositionArray]="json"  [indexofelement]="subdata.element"  *ngIf="subdata?.elementType == this.constants.SEPARATOR " ></app-separator>
                <app-table  [formsPositionArray]="json"  [indexofelement]="subdata.element"  *ngIf="subdata?.elementType == this.constants.TABLE " ></app-table>
                <app-address  [formsPositionArray]="json"  [indexofelement]="subdata.element"  *ngIf="subdata?.elementType == this.constants.ADDRESS " ></app-address>
                <app-multiple-choices  [formsPositionArray]="json"  [indexofelement]="subdata.element"  *ngIf="subdata?.elementType == this.constants.MULTIPLECHOICES " ></app-multiple-choices>
                <app-image  [formsPositionArray]="json"  [indexofelement]="subdata.element"  *ngIf="subdata?.elementType == this.constants.IMAGE " ></app-image>

                <app-user-info  [formsPositionArray]="json"  [indexofelement]="subdata.element"  *ngIf="subdata?.elementType == this.constants.USERINFOFIELDS " ></app-user-info>
            
            </div>

            </div>
            <div *ngIf="data.element!=undefined">

                <app-heading [formsPositionArray]="json"  [indexofelement]="data.element" *ngIf="data?.elementType == this.constants.HEADING " ></app-heading>
                <app-selectbox [formsPositionArray]="json"  [indexofelement]="data.element" *ngIf="data?.elementType == this.constants.SELECTBOX " (changeDynamicValue)="changeDynamicValue($event)"></app-selectbox>
                <app-textbox  [formsPositionArray]="json"  [indexofelement]="data.element"  *ngIf="data?.elementType == this.constants.TEXTBOX " ></app-textbox>
                <app-textarea [formsPositionArray]="json"  [indexofelement]="data.element" *ngIf="data?.elementType == this.constants.TEXTAREA " ></app-textarea>
                <app-radio  [formsPositionArray]="json"  [indexofelement]="data.element" *ngIf="data?.elementType == this.constants.RADIO " ></app-radio>
                <app-date-selector  [formsPositionArray]="json"  [indexofelement]="data.element"  *ngIf="data?.elementType == this.constants.DATESELECTOR " ></app-date-selector>
                <app-paragraph [formsPositionArray]="json"  [indexofelement]="data.element" *ngIf="data?.elementType == this.constants.PARAGRAPH " ></app-paragraph>
                <app-separator  [formsPositionArray]="json"  [indexofelement]="data.element"  *ngIf="data?.elementType == this.constants.SEPARATOR " ></app-separator>
                <app-payment  [formsPositionArray]="json"  [indexofelement]="data.element"  *ngIf="data?.elementType == this.constants.PAYMENT " ></app-payment>
                <app-table  [formsPositionArray]="json"  [indexofelement]="data.element"  *ngIf="data?.elementType == this.constants.TABLE " ></app-table>
                <app-address  [formsPositionArray]="json"  [indexofelement]="data.element"  *ngIf="data?.elementType == this.constants.ADDRESS " ></app-address>
                <app-multiple-choices  [formsPositionArray]="json"  [indexofelement]="data.element"  *ngIf="data?.elementType == this.constants.MULTIPLECHOICES " ></app-multiple-choices>
                <app-image  [formsPositionArray]="json"  [indexofelement]="data.element"  *ngIf="data?.elementType == this.constants.IMAGE " ></app-image>

                <app-user-info  [formsPositionArray]="json"  [indexofelement]="data.element"  *ngIf="data?.elementType == this.constants.USERINFOFIELDS " ></app-user-info>

            </div> 


        </div>
        </div>
        <div class="error font-bold" id="formError" *ngIf="jsonStruct.length>0" #formError></div>

        <div class="error-msg errorOnLoad text-center" style="font-size: 20px;font-weight: bold;" #errorOnLoad></div>


        <div class="d-flex-custom-form justify-content-center mrgt-30" *ngIf="jsonStruct.length>0">
            <button class="clearBtnForm text-white cursor" id="clearBtn" (click)="showHideOverlayForm(1,'clear')">Clear</button>
            <button class="submitBtnForm mrgl-10 text-white cursor" id="submitBtn" (click)="submitForm()">{{(formResponse.paymentOptionUuid==undefined) ? 'Submit' : 'Submit & Pay'}}</button>
        </div>        
        <div class="h-20"></div>
    </div>

    <div class="h-30"></div>

</div>

</div>