import {
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { FormCommonMethodsService } from "src/app/utils/form-common-methods.service";

@Component({
  selector: "app-image",
  templateUrl: "./image.component.html",
  styleUrls: ["./image.component.css", "../../formcommon.css"],
})
export class ImageComponent {
  @Input() indexofelement: any;
  @Input() formsPositionArray: any;
  @ViewChild("imageTag") imageTag!: ElementRef;
  public imagesrc: any = null;
  public height: String = "";
  public width: String = "";

  constructor(
    private renderer: Renderer2,
    private formCommonMethods: FormCommonMethodsService
  ) {}

  ngOnChanges(a: any) {
    if ("formsPositionArray" in a || "indexofelement" in a) {
      var found: any = this.formCommonMethods.checkIfInJsonArray(
        this.formsPositionArray,
        this.indexofelement
      );

      setTimeout(() => {
        this.setAttributeChange(this.formsPositionArray[found]);
      }, 50);
    }
  }

  setAttributeChange(data: any) {
    if (data.imgDetails != undefined && data.imgDetails != null) {
      this.imagesrc = data.imgDetails.itemUrl;
    }

    let ImageCont: any = document.getElementById("ImageCont-" + data.element);

    if (data.hide != undefined && data.hide == true) {
      ImageCont.classList.add("d-none-custom-form");
    }

    if (data.align != undefined && data.align != "") {
      ImageCont.style.textAlign = data.align;
    }
    if (data.width != undefined && data.width != "") {
      this.width = data.width;
    }
    if (data.height != undefined && data.height != "") {
      this.height = data.height;
    }
    setTimeout(() => {
      // if (this.height != null)
      //   this.imageTag.nativeElement.style.height = this.height + "px";
      // if (this.width != null)
      //   this.imageTag.nativeElement.style.width = this.width + "px";
      this.imageTag.nativeElement.style.width =  "100%";
    }, 10);
  }
}
